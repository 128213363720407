<template>
    <div class="container">
        <div class="container-body">
            <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
                <!-- <el-row>
                  <el-col :span="24">
                      <div class="grid-content bg-purple content" >
                         <img src="../../assets/index-bg.jpg" alt="" style="width:58.5vw;height:100%;margin-left:-3.8vw;">
                      </div>
                  </el-col>
              </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <div class="grid-content bg-purple title" style="height:70px;width:128px;">
                            <h3>关于我们</h3>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>
                                北京赛尔汇力安全科技有限公司（简称：赛尔安全）成立于2007年，专业致力于网络安全、信息安全、信息系统建设等网络安全服务赛尔安全的控股方赛尔网络有限公司是由教育部科技发展中心与清华大学、北京大学等十多所著名高校共同出资兴办的，是中国教育和科研计算机网的运营服务商。</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>——中国第一个互联网主干网，目前用户超过2500万 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>—— 全国29家分公司，强大的服务体系 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>—— 国内首家网络安全应急响应体系（CCERT），累计处理2000多件网络安全事件报告 </p>
                        </div>
                    </el-col>
                    <el-row>
                        <el-col :span="6">
                            <div class="grid-content bg-purple title" style="height:50px;width:195px;">
                                <h3>赛尔安全的特点</h3>
                            </div>
                        </el-col>
                    </el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <h4> 顶级战略规划： </h4>
                        </div>
                        <div class="grid-content bg-purple content">
                            <p>拥有国内知名互联网领域战略专家小组站在互联网战略升级的高度进行网络安全的顶层设计和战略统筹 。 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content" style="margin-top:10px;">
                            <h4> 军民融合技术： </h4>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>1、从人才和技术两个层面开展军用技术和民用技术的实践结合，完善网络安全体系架构，实现最新技术的协同创新。 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>2、与清华大学，北邮等全国重点高校密切开展产学研合作，加强了企业攻坚实力 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content" style="margin-top:10px;">
                            <h4> 品牌信誉保证：</h4>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>
                                赛尔作为教育部直属的国内最早建立互联网的公司，正在肩负下一代互联网ipv6升级的使命，赛尔安全作为旗下专业网络安全服务商，秉承赛尔品牌“牢记使命，创新网络，提升质量，服务用户”的理念。
                            </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content" style="margin-top:10px;">
                            <h4> 专属定制服务：</h4>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>1、分析不同行业用户系统及子系统的安全需求，针对网络架构、威胁防护、策略、区域划分、系统运维等安全情况定制专属方案，实现一对一服务。 </p>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content">
                            <p>2、公司拥有全天候专业售后团队，对客户的突发状况作出24小时的实时处理 。 </p>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content"
                             style="width:50vw;margin: 0 auto 2vw;">
                            <img src="../../assets/image_20190321160455_814.png" alt="" style="width:100%;height:100%;">
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content" style="margin:20px ">
                            <h4> 赛尔安全资质认证：</h4>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="grid-content bg-purple content"
                             style="width:50vw;margin: 0 auto 3vw;">
                            <img src="../../assets/image_20190321160425_252.png" alt="" style="width:100%;height:100%;">
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                xxx: '\u3000\u3000',
                ClientWidth_number: document.documentElement.clientWidth,
            };
        },

        created() {
            this.getClientWidth();
        },

        methods: {
            getClientWidth() {
                window.onresize = () => {
                    this.ClientWidth_number = document.documentElement.clientWidth;
                };
            },

        },
    };
</script>

<style lang='less' scoped>

    @baseWidth: 1980;

    .pxfix(@w) {
        width: (@w / @baseWidth) * 100vw;
    }

    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .container-body {
        .pxfix(1980);
        min-height: 700px;
        margin: 0 auto;
        background: #eff3f8;
        // margin-top: 20px;
        display: flex;
        flex: 1;

        .title {
            font-size: 1.2rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-weight: normal;
            font-family: Microsoft YaHei, serif;
            color: #555;
        }

        .content {
            margin: 0 1rem;
            color: #555;
            font-size: 0.8rem;
            line-height: 25px;
        }

        .body-box {
            .pxfix(1040);
            margin: 1.5rem auto;
            //  flex:1;
            padding: 0 3vw;
            background: #fff;
        }

        .body-box-phone {
            background: #fff;
            margin: 1.5rem auto;
        }

        .body-box:hover {
            box-shadow: 0 0 10px #888888;
        }
    }

    .footer {
        line-height: 50px;
        background: #2f3034;
        width: 100%;
        font-size: 12px;
        color: #fff;
        text-align: center;
    }
</style>
